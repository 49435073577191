import {createRouter, createWebHistory} from 'vue-router'
import store from "@/store";

const routes = [
    {
        name: 'home',
        path: '/',
        component: () => import('@/views/Root'),
    },
    {
        name: 'set_password',
        path: '/set_password',
        component: () => import('@/views/SetPassword'),
    },
    {
        name: '404',
        path: '/:pathMatch(.*)*',
        component: () => import('@/views/NotFound'),
    }
]

export const history = createWebHistory();

const router = createRouter({history, routes});

router.beforeEach((to) => {
    const auth = store.state.auth.token;
    // if (!auth && to.name !== 'home') {
    //     return {name: 'home'};
    // }
    // for (const match of to.matched) {
    //     if (match.meta && match.meta.tool && !store.state.auth.data.permissions.tools.includes(match.meta.tool)) {
    //         return {name: 'home'};
    //     }
    // }
});

router.afterEach((to) => {
    // try {
    //     document.title = to.meta.title && to.meta.title(to);
    // } catch (e) {
    //     document.title = "Crispin Data+Analytics";
    // }
})

export default router;
